import React from 'react';

import { wrapper } from './user-loading.module.scss';

import Loader from '../atoms/loader';

const UserLoading = () => {
    return (
        <div className={wrapper}>
            <Loader />
        </div>
    );
};

export default UserLoading;
