import React from 'react';

import { errorBox, loader } from './user-order-data.module.scss';

import { IOrder } from '../../models/order.model';
import { useOrder } from '../../hooks/use-order';
import { getAllMediaByRelation } from '@alterpage/gatsby-plugin-image';
import { relations } from '../../config/relations';

import Loader from '../atoms/loader';
import UserOrderHeader from '../molecules/user-order-header';
import UserOrderDetails from './user-order-details';

interface IUserOrderDataProps {
    orderHistoryPage: string;
    subtitle: string | null;
}

const UserOrderData: React.FC<IUserOrderDataProps> = ({ orderHistoryPage, subtitle }) => {
    const orderState = useOrder({ queries: ['single'] });
    const order = orderState.single.data;
    const invoices = order && getOrderInvoices(order);

    return (
        <>
            {orderState.single.isLoading && <Loader className={loader} />}
            {!orderState.single.isLoading && (
                <>
                    <UserOrderHeader
                        title={`${subtitle} ${order?.uid}`}
                        returnPath={orderHistoryPage}
                        invoices={invoices}
                    />
                    {orderState.single.isError && (
                        <div className={errorBox}>
                            {orderState.single.errors?.map((error) => {
                                return <p>{error.content}</p>;
                            })}
                        </div>
                    )}
                    {orderState.single.isSuccess && order && <UserOrderDetails order={order} />}
                </>
            )}
        </>
    );
};

function getOrderInvoices(order: IOrder) {
    if (order.media) {
        return [
            ...getAllMediaByRelation(order.media, relations.invoiceDocument),
            ...getAllMediaByRelation(order.media, relations.correctInvoiceDocument),
            ...getAllMediaByRelation(order.media, relations.proformaInvoiceDocument),
        ];
    }
    return [];
}

export default UserOrderData;
