import React from 'react';

import { childrenWrapper } from './user-order.module.scss';

import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { usePagePathname } from '../../hooks/use-page-pathname';

import UserLayout from '../../layouts/user-layout';
import UserOrderData from '../organisms/user-order-data';

export interface IUserOrderSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IUserOrderProps {
    className?: string;
    section: IUserOrderSection;
}

const UserOrder = ({ section, className = '' }: IUserOrderProps) => {
    const { sectionId, css, style } = section;
    const [subtitle] = section.content.texts;
    const { links } = section.items;
    const paths = usePagePathname();

    return (
        <UserLayout
            sectionId={sectionId}
            className={className}
            links={links}
            childrenWrapperClassName={childrenWrapper}
            css={css}
            style={style}
        >
            <UserOrderData orderHistoryPage={paths.userOrders || '/'} subtitle={subtitle} />
        </UserLayout>
    );
};

export default UserOrder;
